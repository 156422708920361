// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#_5\\+r9YDjcgjUenhxZz4wvbA\\=\\= > li#TNWpqiE-9JsFlJZJusedbA\\=\\= {\n  margin-right: 0 !important;\n}", "",{"version":3,"sources":["webpack://./../design-system/lib/pagination/DsPagination.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":["#paginationContainer > li#nextPage {\n  margin-right: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": "_5+r9YDjcgjUenhxZz4wvbA==",
	"nextPage": "TNWpqiE-9JsFlJZJusedbA=="
};
export default ___CSS_LOADER_EXPORT___;
