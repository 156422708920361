"use strict";

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

exports.__esModule = true;

var _DsBadge = require("./badge/DsBadge");

Object.keys(_DsBadge).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsBadge[key]) return;
  exports[key] = _DsBadge[key];
});

var _DsButton = require("./button/DsButton");

Object.keys(_DsButton).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsButton[key]) return;
  exports[key] = _DsButton[key];
});

var _DsButtonCollapse = require("./button/DsButtonCollapse");

Object.keys(_DsButtonCollapse).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsButtonCollapse[key]) return;
  exports[key] = _DsButtonCollapse[key];
});

var _DsButtonToggle = require("./button/DsButtonToggle");

Object.keys(_DsButtonToggle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsButtonToggle[key]) return;
  exports[key] = _DsButtonToggle[key];
});

var _DsCard = require("./card/DsCard");

Object.keys(_DsCard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsCard[key]) return;
  exports[key] = _DsCard[key];
});

var _DsFileTypeIcon = require("./card/DsFileTypeIcon");

Object.keys(_DsFileTypeIcon).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsFileTypeIcon[key]) return;
  exports[key] = _DsFileTypeIcon[key];
});

var _DsContextualHelp = require("./contextual-help/DsContextualHelp");

Object.keys(_DsContextualHelp).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsContextualHelp[key]) return;
  exports[key] = _DsContextualHelp[key];
});

var _DocumentViewer = require("./document-viewer/DocumentViewer");

Object.keys(_DocumentViewer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DocumentViewer[key]) return;
  exports[key] = _DocumentViewer[key];
});

var _DsDropDown = require("./drop-down/DsDropDown");

Object.keys(_DsDropDown).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsDropDown[key]) return;
  exports[key] = _DsDropDown[key];
});

var _DsDropZone = require("./drop-zone/DsDropZone");

Object.keys(_DsDropZone).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsDropZone[key]) return;
  exports[key] = _DsDropZone[key];
});

var _DsPagination = require("./pagination/DsPagination");

Object.keys(_DsPagination).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsPagination[key]) return;
  exports[key] = _DsPagination[key];
});

var _DsSgDatePicker = require("./picker/DsSgDatePicker");

Object.keys(_DsSgDatePicker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsSgDatePicker[key]) return;
  exports[key] = _DsSgDatePicker[key];
});

var _DsSgDateRangePicker = require("./picker/DsSgDateRangePicker");

Object.keys(_DsSgDateRangePicker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsSgDateRangePicker[key]) return;
  exports[key] = _DsSgDateRangePicker[key];
});

var _DsSgPicker = require("./picker/DsSgPicker");

Object.keys(_DsSgPicker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsSgPicker[key]) return;
  exports[key] = _DsSgPicker[key];
});

var _DsSgTimePicker = require("./picker/DsSgTimePicker");

Object.keys(_DsSgTimePicker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsSgTimePicker[key]) return;
  exports[key] = _DsSgTimePicker[key];
});

var _DsStepper = require("./stepper/DsStepper");

Object.keys(_DsStepper).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsStepper[key]) return;
  exports[key] = _DsStepper[key];
});

var _DsToastAlert = require("./toast/DsToastAlert");

Object.keys(_DsToastAlert).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsToastAlert[key]) return;
  exports[key] = _DsToastAlert[key];
});

var _DsToastContextual = require("./toast/DsToastContextual");

Object.keys(_DsToastContextual).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DsToastContextual[key]) return;
  exports[key] = _DsToastContextual[key];
});