// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uSuZbygMfV6lppbsfBhwAw\\=\\= ._9FIvzuvxLjzK-DxVelrjjA\\=\\= {\n  position: absolute;\n  width: 50vw;\n  height: 24px;\n}\n\n.uSuZbygMfV6lppbsfBhwAw\\=\\= ._9FIvzuvxLjzK-DxVelrjjA\\=\\=._45E7ggUX24847F\\+hkoAg0w\\=\\= {\n  left: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #000000 calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n.uSuZbygMfV6lppbsfBhwAw\\=\\= ._9FIvzuvxLjzK-DxVelrjjA\\=\\=.mUS6c8lMvTddfO4Tn4s4dA\\=\\= {\n  right: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #6e777a calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n.uSuZbygMfV6lppbsfBhwAw\\=\\= .Voi7Vq0QengYkC9HJlrqmA\\=\\= .xOj3x-o6pvw679JRY0ntww\\=\\=.u6kPQBy9jQJMZI7uu0Gwdg\\=\\= .dy2hkieM6BaMewaBFSc64A\\=\\=,\n.uSuZbygMfV6lppbsfBhwAw\\=\\= .Voi7Vq0QengYkC9HJlrqmA\\=\\= .sPqRw6TYBo0pkH9mm2GNbw\\=\\= {\n  background-color: white;\n}\n", "",{"version":3,"sources":["webpack://./../design-system/lib/stepper/DsStepper.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,OAAO;EACP;;;;;GAKC;AACH;;AAEA;EACE,QAAQ;EACR;;;;;GAKC;AACH;;AAEA;;EAEE,uBAAuB;AACzB","sourcesContent":[".ds-stepper .track {\n  position: absolute;\n  width: 50vw;\n  height: 24px;\n}\n\n.ds-stepper .track.track--left {\n  left: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #000000 calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n.ds-stepper .track.track--right {\n  right: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #6e777a calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n.ds-stepper .stepper .stepper-step.current .stepper-indicator,\n.ds-stepper .stepper .stepper-step-inner {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ds-stepper": "uSuZbygMfV6lppbsfBhwAw==",
	"track": "_9FIvzuvxLjzK-DxVelrjjA==",
	"track--left": "_45E7ggUX24847F+hkoAg0w==",
	"track--right": "mUS6c8lMvTddfO4Tn4s4dA==",
	"stepper": "Voi7Vq0QengYkC9HJlrqmA==",
	"stepper-step": "xOj3x-o6pvw679JRY0ntww==",
	"current": "u6kPQBy9jQJMZI7uu0Gwdg==",
	"stepper-indicator": "dy2hkieM6BaMewaBFSc64A==",
	"stepper-step-inner": "sPqRw6TYBo0pkH9mm2GNbw=="
};
export default ___CSS_LOADER_EXPORT___;
