// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+WOVtH5uY-B-gq2QsMVyIg\\=\\=:hover {\n    color: #000000 !important;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./../design-system/lib/contextual-help/DsContextualHelp.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".contextual-help:hover {\n    color: #000000 !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextual-help": "+WOVtH5uY-B-gq2QsMVyIg=="
};
export default ___CSS_LOADER_EXPORT___;
