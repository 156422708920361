// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-0fd58cdfaf/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cr\\+j00XtRm-AtqnowjEwMw\\=\\=.jKD52pupWVgDAlYhOONi-Q\\=\\= > input,\n.cr\\+j00XtRm-AtqnowjEwMw\\=\\=.o6rKQsCoBAtkl8ps0zvoSQ\\=\\= > input {\n    display: none;\n}\n\nbutton:disabled {\n    cursor: not-allowed;\n}\n\n.cr\\+j00XtRm-AtqnowjEwMw\\=\\=.iAxOIv1R4eOLyZhxdbLDsQ\\=\\= .r6Y-0OMsdPDMq9vtV\\+npkw\\=\\=, .cr\\+j00XtRm-AtqnowjEwMw\\=\\=:disabled .r6Y-0OMsdPDMq9vtV\\+npkw\\=\\= {\n    cursor: not-allowed;\n}", "",{"version":3,"sources":["webpack://./../design-system/lib/button/DsButton.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".btn.btn-toggle-primary > input,\n.btn.btn-toggle-secondary > input {\n    display: none;\n}\n\nbutton:disabled {\n    cursor: not-allowed;\n}\n\n.btn.disabled .icon, .btn:disabled .icon {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "cr+j00XtRm-AtqnowjEwMw==",
	"btn-toggle-primary": "jKD52pupWVgDAlYhOONi-Q==",
	"btn-toggle-secondary": "o6rKQsCoBAtkl8ps0zvoSQ==",
	"disabled": "iAxOIv1R4eOLyZhxdbLDsQ==",
	"icon": "r6Y-0OMsdPDMq9vtV+npkw=="
};
export default ___CSS_LOADER_EXPORT___;
